<template>
    <div class="card-body">
        <div class="" v-if="!isLoad && materials.length">
            <div v-for="(item, index) in materials" :key="index" class="card w-100 p-3 bg-grey p-lg-3 border-0 mb-3 rounded-lg d-block float-left">
                <div class="row mb-1">
                    <div class="col-12">
                        <router-link :to="{name: 'MyCourseMaterialsDetail', params : {idCourse: paramsId, idMaterial: item.id}}" class="float-left mb-0 text-current fw-700">{{item.title | truncate(30, '...')}}</router-link>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <span class="font-xssss fw-700 text-grey-600 d-block">{{item.description | truncate(50, '...')}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-right">
                        <span class="font-xssss fw-600 text-grey-500 d-block mr-1 text-right">Created at : </span>
                        <small class="text-right">{{item.created_at | formatDate}}</small>
                    </div>
                    <div class="col-12 text-right mt-2">
                        <router-link :to="{name: 'MyCourseMaterialsDetail', params : {idCourse: paramsId, idMaterial: item.id}}" class="btn btn-current">See More</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center py-3">
                <div class="my-3" v-if="isLoad">
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>

                </div>
                <img :src="'/images/empty.png'" v-if="!isLoad && !materials.length" alt="" width="300">
                <h1 class="text-muted" v-if="!isLoad && !materials.length">Data don't exist</h1>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import RecordedVideoModal from './RecorderVideoModal.vue'
export default {
    data(){
        return {
            isLoad: true,
            paramsId: this.$route.params.idCourse,
            media: process.env.VUE_APP_URL_CLOUD,
            materials: {}
        }
    },
    props: ['unit', 'cycle'],
    watch: {
        unit:function(){
            this.isLoad = true
            this.getMaterials()
        },
        cycle:function(){
            this.isLoad = true
            this.getMaterials()
        },
    },
    created(){
        this.getMaterials()
    },
    methods: {
        scrollToEnd() {
            setTimeout(function () {
                let container = document.querySelector("html");
                if(container) {
                    container.scrollTop = 700;
                }
            }, 100);
        },
        showModal(path){
            this.$modal.show(
                RecordedVideoModal,
                    { path: path },
                    {   
                    width: "50%",
                    height: "auto"  
                    },
                )
        },
        async getMaterials(){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/material?slug_course=${this.paramsId}&type=1&cycle=${this.cycle}&unit=${this.unit}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.materials = res.data
                this.isLoad = false
                this.scrollToEnd()
            }).catch((err) => console.error(err.response))
        },
    }
}
</script>