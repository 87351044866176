<template>
<div name="example">
    <div class="row">
        <div class="col-12">
            <div class="btn bg-transparent float-right" @click="$modal.hideAll()">
                <i class="fas fa-times font-md m-2"></i>
            </div>
        </div>
        <div class="col-12 text-center">
            <video width="100%" height="auto" controls>
                <source :src="path" type="video/mp4">
            </video>
        </div>
    </div>
</div>
</template>
<script>
export default {
  name: 'VidModal',
  data () {
    return {
    }
  },
  props: {
    path: String
  },
}
</script>