<template>
<div name="example">
    <div class="row">
        <div class="col-12">
            <div class="btn bg-transparent float-right" @click="$modal.hideAll()">
                <i class="fas fa-times font-md"></i>
            </div>
        </div>
        <div class="col-12">
            <!-- <VueDocPreview :value="path" type="markdown" /> -->
            <object :data="path" type="application/pdf" class="w-100 vh-100">
                <embed :src="path" type="application/pdf" />
            </object>
        </div>
    </div>
</div>
</template>
<script>
// import VueDocPreview from 'vue-doc-preview'
export default {
    name: 'Modal',

    data () {
        return {
        }
    },
    props: {
        path: String
    },
}
</script>