<template>
  <div name="example">
      <div class="row">
          <div class="col-12">
            <div class="btn bg-transparent float-right" @click="$modal.hideAll()">
                <i class="fas fa-times font-md"></i>
            </div>
          </div>
          <div class="col-12">
            <audio controls class="w-100">
                <source :src="path" type="audio/mpeg">
                Your browser does not support the audio element.
            </audio>

          </div>
      </div>
</div>
</template>
<script>
export default {
  name: 'VidModal',
  data () {
    return {
    }
  },
  props: {
    path: String
  },
}
</script>